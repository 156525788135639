// constants
import COLORS from '../../colors';
import { BACKGROUND_COLOR_ALPHA_VALUE } from '../../styles';
import { RULE_SORT_ORDER } from '../automationRuleType';

// services
import {
  colorForStatus,
  orderByStatus,
  translationForStatus,
  COMMUNICATION_ERROR,
  DISABLED,
  ONGOING,
  OFF,
  SYNCHRONIZING,
  NO_ERROR,
  IN_ERROR,
} from '../status/automationStatus';
import {
  commandButtonIconForType,
  commandButtonTextForType,
  commandTypeForID,
  ADD_TIME,
  REMOVE_TIME,
  UNKNOWN,
} from '../automationCommandType';
import AutomationRuleEntity from './automationRuleEntity';
import SubSensorReadingEntity from './subSensorReadingEntity';

export default class AutomationEntity {
  constructor(component) {
    this.id = component.id;
    this.name = component.name;
    this.alias = component.alias;
    this.ongoing = component.ongoing;
    this.commands = component.commands;
    this.status = component.status;
    this.currentExecution = component.currentExecution;
    this.automationRules = component.rules
      ?.map((rule) => new AutomationRuleEntity(rule))
      .filter((rule) => RULE_SORT_ORDER.includes(rule.ruleType));
    this.subSensorsReading = component.subSensorsReading?.map((subSensor) => new SubSensorReadingEntity(subSensor));
  }

  isInError() {
    return (
      this.status.inError ||
      (this.status.error != null && this.status.error.errorCodes != null && this.status.error.errorCodes !== NO_ERROR)
    );
  }

  statusType() {
    if (this.isInError()) {
      return this.status.error?.errorCodes || IN_ERROR;
    }

    switch (true) {
      case this.status.communicationError:
        return COMMUNICATION_ERROR;
      case this.status.disabled:
        return DISABLED;
      case this.ongoing:
        return ONGOING;
      case this.status.synchronizing:
        return SYNCHRONIZING;
      default:
        return OFF;
    }
  }

  statusColor() {
    return colorForStatus(this.statusType());
  }

  statusOrder() {
    return orderByStatus(this.statusType());
  }

  aliasBackgroundColor() {
    const status = this.statusType();
    switch (status) {
      case DISABLED:
      case COMMUNICATION_ERROR:
        return COLORS.greyish22;
      default:
        return `${this.statusColor()}${BACKGROUND_COLOR_ALPHA_VALUE}`;
    }
  }

  itemBackgroundColor() {
    const status = this.statusType();
    switch (status) {
      case DISABLED:
      case COMMUNICATION_ERROR:
      case SYNCHRONIZING:
        return COLORS.greyish20;
      default:
        return COLORS.white;
    }
  }

  statusText(t) {
    return t(translationForStatus(this.statusType()));
  }

  lastUpdated() {
    return this.status?.lastHeartbeat;
  }

  getCommandsInfo(t) {
    const uniqueButtonID = new Set();
    const buttonsInfo = [];

    this.commands.map((command) => {
      const commandID = parseInt(command.id, 10);
      const type = commandTypeForID(commandID);

      if (type !== UNKNOWN && !uniqueButtonID.has(type.groupID)) {
        const textTranslationKey = commandButtonTextForType(type);

        command.icon = commandButtonIconForType(type);
        command.text = t(textTranslationKey);
        command.testID = textTranslationKey;

        uniqueButtonID.add(type.groupID);
        buttonsInfo.push(command);
      }
    });

    return buttonsInfo;
  }

  hasModifyType() {
    return this.commands.some((command) => {
      const type = commandTypeForID(command.id);
      return type.name === ADD_TIME.name || type.name === REMOVE_TIME.name;
    });
  }

  getEnabledRules() {
    return this.automationRules?.filter((rule) => rule.enabled);
  }

  getRPMSubSensors() {
    return this.subSensorsReading?.filter((subSensor) => subSensor.isRPM);
  }

  getRPMValues() {
    return this.getRPMSubSensors()?.map((subSensor) => subSensor.getRPMValues());
  }

  setCoordinates(coordinates) {
    this.coordinates = coordinates;
  }
}
