import { BLOCK_ORDER } from '../models/blockSorting';
import { LOCAL_DATA, WEATHER_DATA } from '../models/dataStreamTypes';
import { getApolloClient } from '../clients/apollo';
import { MAP_BLOCKS } from './mapBlocks.graphql';

const fetchMapBlocks = async (siteId, sortBlocksBy = BLOCK_ORDER, blockFilters = { capabilities: [], tensionStatuses: [] }) => {
  const { loading, error, data } = await getApolloClient().query({
    query: MAP_BLOCKS,
    variables: {
      siteId,
      blockSorting: sortBlocksBy,
      filters: blockFilters,
      dataStreamTypes: [...LOCAL_DATA, ...WEATHER_DATA],
    },
  });

  const blocks = data?.viewer.site.blocks;

  return {
    loading,
    error,
    blocks,
  };
};

export { fetchMapBlocks };
