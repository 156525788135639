import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { AUTOMATIONS } from './automations.graphql';

const fetchAutomations = async (siteId) => {
  let automations;

  const { loading, error, data } = await getApolloClient().query({
    query: AUTOMATIONS,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  if (data) {
    automations = data.viewer.site.automations;
  }

  return {
    loading,
    error,
    automations,
  };
};

export { fetchAutomations };
