import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { AUTOMATION } from '../models/modules';
import { MAP_ITEMS } from './mapItems.graphql';

async function fetchMapItems(currentSite) {
  const siteId = currentSite?.id;
  const includeAutomations = currentSite?.modules.includes(AUTOMATION);

  const { loading, error, data } = await getApolloClient().query({
    query: MAP_ITEMS,
    variables: { siteId, includeAutomations },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  const mapItems = data?.viewer.site.mapItems;
  const automations = data?.viewer.site.automations || [];

  return {
    loading,
    error,
    mapItems,
    automations,
  };
}

export { fetchMapItems };
