import React from 'react';
import { Image, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import {
  DISABLED,
  OFF,
  ONGOING,
  SYNCHRONIZING,
  COMMUNICATION_ERROR,
  DEVICE_CONFIGURATION_ERROR,
  ERROR_READING_COMPONENT,
  ERROR_READING_FEEDBACK,
  ERROR_COMPONENT_WRONG_STATE,
  ERROR_FEEDBACK,
  ERROR_COMPONENT_NOT_READY,
  ERROR_READING_SENSOR,
  ERROR_COMMUNICATION_LOST,
  ERROR_CONTROL_RETRY,
  ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF,
  ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON,
  ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON,
  ERROR_MOTOR_CONTROLLER,
  IN_ERROR,
} from '../../models/status/automationStatus';

// icons
import icnDisabled from '../../assets/icons/icnDisabled.png';
import icnIrrigationOff from '../../assets/icons/icnIrrigationOff.png';

// entity
import AutomationEntity from '../../models/entities/automationEntity';

// components
import Irrigation from '../block/Irrigation';
import Synchronization from '../animation/Synchronization';

const inError = (status) => {
  return (
    status === COMMUNICATION_ERROR ||
    status === DEVICE_CONFIGURATION_ERROR ||
    status === ERROR_READING_COMPONENT ||
    status === ERROR_READING_FEEDBACK ||
    status === ERROR_COMPONENT_WRONG_STATE ||
    status === ERROR_FEEDBACK ||
    status === ERROR_COMPONENT_NOT_READY ||
    status === ERROR_READING_SENSOR ||
    status === ERROR_COMMUNICATION_LOST ||
    status === ERROR_CONTROL_RETRY ||
    status === ERROR_OUTPUT_FEEDBACK_HIGH_WHILE_OFF ||
    status === ERROR_OUTPUT_FEEDBACK_LOW_WHILE_ON ||
    status === ERROR_OUTPUT_FEEDBACK_TOO_HIGH_WHILE_ON ||
    status === ERROR_MOTOR_CONTROLLER ||
    status === IN_ERROR
  );
};

const AutomationStatusIcon = ({ entity, animatedOpacity, big }) => {
  const status = entity.statusType();

  switch (true) {
    case status === SYNCHRONIZING:
      return <Synchronization testID={'automation__status-icon-synchronizing'} big={big} />;
    case status === DISABLED:
      return (
        <Image style={big ? styles.bigImage : styles.image} source={icnDisabled} testID={'automation__status-icon-disabled'} />
      );
    case status === OFF:
      return (
        <Image style={big ? styles.bigImage : styles.image} source={icnIrrigationOff} testID={'automation__status-icon-off'} />
      );
    case status === ONGOING:
    case inError(status) && entity.ongoing:
      return (
        <Irrigation
          irrigating={entity.ongoing}
          testID={'automation__status-icon-ongoing'}
          animatedOpacity={animatedOpacity}
          big={big}
        />
      );
    default:
      return null;
  }
};

AutomationStatusIcon.propTypes = {
  entity: PropTypes.instanceOf(AutomationEntity).isRequired,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
  big: PropTypes.bool,
};

const styles = StyleSheet.create({
  bigImage: {
    width: 52,
    height: 52,
  },
  image: {
    width: 32,
    height: 32,
  },
});

export default AutomationStatusIcon;
