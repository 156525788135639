import { gql } from 'graphql-tag';

export const AUTOMATIONS = gql`
  query automations($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        name
        automations {
          id
          name
          alias
          ongoing
          commands {
            id
            name
            alias
          }
          status {
            automationId
            communicationError
            disabled
            synchronizing
            inError
            lastHeartbeat
            error {
              kind
              component
              resourceId
              errorCodes
            }
          }
          currentExecution {
            id
            automationId
            startDate
            endDate
            plannedDuration
          }
          rules {
            id
            name
            enabled
            properties {
              ... on AutomationBySensor {
                ruleType
                triggerValues {
                  ... on AutomationTriggerOnHighValue {
                    startAbove
                    stopBelow
                    unit
                  }
                  ... on AutomationTriggerOnLowValue {
                    startBelow
                    stopAbove
                    unit
                  }
                }
              }
            }
          }
          subSensorsReading {
            id
            type
            value
            unit
          }
        }
      }
    }
  }
`;
