import { gql } from 'graphql-tag';

export const MAP_BLOCKS = gql`
  query mapBlocks($siteId: ID!, $blockSorting: blockSort, $filters: blockFilters, $dataStreamTypes: [dataStreamType]) {
    viewer {
      site(id: $siteId) {
        id
        blocks(sortBy: $blockSorting, filters: $filters) {
          id
          name
          order
          alias
          lastUpdated
          coordinates {
            longitude
            latitude
          }
          eventProducers(eventTypes: [IRRIGATION]) {
            color
            id
          }
          capabilities {
            temperature {
              status
              protectionState
              unit
              value
              readings {
                id
                depth
                unit
                value
                colorCode
                dataStream {
                  id
                  value
                  unit
                  dataStreamType
                }
              }
            }
            tension {
              status
              optimalTension {
                maximum
                minimum
                unit
              }
              readings {
                id
                depth
                depthUnit
                unit
                value
                colorCode
                dataStream {
                  id
                  value
                  unit
                  minimumValue
                  maximumValue
                  dataStreamType
                  specifiedRange {
                    maximum
                  }
                }
              }
            }
            weatherStation {
              status
              airTemperature {
                unit
                value
              }
              windSpeed {
                unit
                value
              }
              windDirection {
                unit
                value
              }
            }
            flowStation {
              isFlowing
              status
              flowmeters {
                description
                isFlowing
                totalVolume {
                  unit
                  value
                }
                outflow {
                  unit
                  value
                  dataStream {
                    dataStreamType
                    id
                    isVirtual
                    lastUpdated
                    unit
                    value
                  }
                }
              }
            }
            irrigation {
              isIrrigating
            }
          }
          lastUpdated
          sensors {
            dataStreams(dataStreamTypes: $dataStreamTypes) {
              id
              unit
              value
              minimumValue
              maximumValue
              dataStreamType
              isVirtual
              description
            }
          }
        }
      }
    }
  }
`;
