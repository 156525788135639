import { isNullOrUndefined } from '../../utils/util';

export default class SubSensorReadingEntity {
  constructor(subSensorReading) {
    this.subSensorID = subSensorReading.id;
    this.subSensorType = subSensorReading.type;
    this.value = isNullOrUndefined(subSensorReading.value) ? null : subSensorReading.value;
    this.unit = subSensorReading.unit;
    this.isRPM = this.unit === 'RPM';
  }

  getRPMValues() {
    return this.isRPM ? (this.value < 0 ? 0 : this.value) : null;
  }
}
