// presenters
import { MapDisplayMode, MapStyleMode } from '../presenters/MapDisplayMode';

// queries
import { fetchMapBlocks } from '../queries/mapBlocks';
import { fetchMapItems } from '../queries/mapItems';

// models
import BlockEntity from './entities/blockEntity';
import { BLOCK_ORDER } from './blockSorting';
import { executeApolloQuery } from './apolloHelper';
import AutomationEntity from './entities/automationEntity';

export const map = {
  state: {
    blocks: [],
    automations: [],
    items: [],
    mode: MapDisplayMode.TENSION,
    style: MapStyleMode.SATELLITE,
  },

  reducers: {
    updateMap(state, data) {
      const blocks = data.blocks.map((block) => {
        return new BlockEntity(block);
      });
      return { ...state, blocks };
    },
    resetMapData(state) {
      return { ...state, automations: [], blocks: [], items: [] };
    },
    updateMapItems(state, data) {
      const items = data.mapItems?.features || [];
      const itemMap = new Map(items.map((item) => [item.id, item]));

      const automations = data.automations.map((component) => {
        const automation = new AutomationEntity(component);
        const item = itemMap.get(automation.id);
        if (item?.geometry?.coordinates) {
          automation.setCoordinates(JSON.parse(item.geometry.coordinates));
        }
        return automation;
      });

      return { ...state, items, automations };
    },
    updateMode(state, mode) {
      return { ...state, mode };
    },
    updateStyle(state, style) {
      return { ...state, style };
    },
  },

  effects: (dispatch) => ({
    async loadMapBlocksForCurrentSite(_payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchMapBlocks(rootState.site.currentSite.id, BLOCK_ORDER),
        (data) => {
          dispatch.blocks.updateSelectedEntityBlock();
          dispatch.map.updateMap(data);
        },
      );

      await executeApolloQuery(
        dispatch,
        async () => await fetchMapItems(rootState.site.currentSite),
        (data) => {
          dispatch.map.updateMapItems(data);
        },
      );
    },

    setMapMode(mode) {
      dispatch.blocks.updateSelectedEntityBlock();
      dispatch.graph.reset();
      dispatch.map.updateMode(mode);
      dispatch.preferences.writeMapMode({ mapMode: mode });
    },

    setMapStyle(style) {
      dispatch.map.updateStyle(style);
      dispatch.preferences.writeMapStyle({ mapStyle: style });
    },

    reset() {
      dispatch.map.resetMapData();
    },
  }),
};
