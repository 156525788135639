import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSharedValue, withTiming, withRepeat } from 'react-native-reanimated';
import PropTypes from 'prop-types';

import SynchronizationDots from './SynchronizationDots';

const TOTAL_ANIMATION_DURATION = 2000;
const NUMBER_OF_DOTS = 3;

const Synchronization = ({ big }) => {
  const progress = useSharedValue(0);

  useEffect(() => {
    progress.value = withRepeat(withTiming(1, { duration: TOTAL_ANIMATION_DURATION }), -1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={[big ? styles.sizeBig : styles.size, styles.container]}>
      {[...Array(NUMBER_OF_DOTS)].map((_e, index) => (
        <SynchronizationDots key={index} index={index} count={NUMBER_OF_DOTS} progress={progress} big={big} />
      ))}
    </View>
  );
};

Synchronization.propTypes = {
  big: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  size: {
    width: 33,
    height: 32,
  },
  sizeBig: {
    width: 52,
    height: 52,
  },
});

export default Synchronization;
